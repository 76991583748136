import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import Notify, { showToast } from "../notify";
import { useRouter } from "next/navigation";

const stripePromise = loadStripe(process.env.NEXT_STRIPE_P_KEY_PATH);

const PurchaseNow = ({ name, price }) => {
  const [loading, setLoading] = useState(false);
  const [authToken, setAuthToken] = useState();

  const router = useRouter();

  useEffect(() => {
    if (localStorage.getItem("LogInJWTkn")) {
      setAuthToken(localStorage.getItem("LogInJWTkn"));
    }
  }, []);

  const createPayment = async () => {
    if (!authToken) router.push("/signin");

    try {
      if (price != 0) {
        setLoading(true);
        const endpoint = `${process.env.NEXT_PUBLIC_API_PATH}payment/request`;
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: authToken,
          },
          body: JSON.stringify({ item: name, price: price }),
        };

        const res = await fetch(endpoint, options);
        const data = await res.json();
        const stripe = await stripePromise;

        if (res.status == 200) {
          const result = await stripe?.redirectToCheckout({
            sessionId: data.payIntent.id,
          });

          if (result.error) {
            showToast(result.error.message, "error");
          }
          setLoading(false);
        } else {
          showToast("Something Went Wrong!", "error");
          setLoading(false);
        }
      } else {
        setLoading(true);
        const endpoint = `${process.env.NEXT_PUBLIC_API_PATH}subscription/request`;
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: authToken,
          },
          body: JSON.stringify({ item: name, price: price }),
        };

        const res = await fetch(endpoint, options);
        const data = await res.json();

        if (res.status == 200) {
          router.push("/images-to-blogs-and-socials");

          setLoading(false);
        } else {
          showToast(data, "error");
          setLoading(false);
        }
      }
    } catch (e) {
      showToast(e, "error");
    }
  };

  return (
    <>
      <Notify />

      {price != 0 && (
        <button
          type="button"
          onClick={createPayment}
          disabled={loading}
          className={`btn-default`}
        >
          {loading && "Processing..."}
          {!loading && "Purchase Now"}
        </button>
      )}

      {price == 0 && (
        <button
          type="button"
          onClick={createPayment}
          disabled={loading}
          className={`btn-default btn-border`}
        >
          {loading && "Processing..."}
          {!loading && "Try It Now"}
        </button>
      )}
    </>
  );
};

export default PurchaseNow;
