import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const checkIsArray = (data) => {
  if (data instanceof Array) return true;
  else return false;
};

const successMsg = (data) => {
  if (checkIsArray(data)) {
    data.map((m) => {
      toast.success(m.message);
    });
  } else {
    toast.success(data);
  }
};

const errorMsg = (data) => {
  if (checkIsArray(data)) {
    data.map((m) => {
      toast.error(m.message);
    });
  } else {
    toast.error(data);
  }
};

const defaultMsg = (data) => {
  if (checkIsArray(data)) {
    data.map((m) => {
      toast(m.message);
    });
  } else {
    toast(data);
  }
};

export const showToast = (message, type = "default") => {
  switch (type) {
    case "success":
      successMsg(message);
      break;
    case "error":
      errorMsg(message);
      break;
    default:
      defaultMsg(message);
      break;
  }
};

export default function Notify() {
  return <ToastContainer autoClose={2000} />;
}
